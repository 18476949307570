<template>
  <form class="form" @submit.prevent="onSaveClick">
    <BasePreloader v-if="pending"
                   style="top: -2rem"/>
    <BaseInput v-model="inputs.password.value"
               :error="inputs.password.error"
               :label="$t('Пароль')"
               type="password"
               class="form__input"/>
    <BaseInput v-model="inputs.password_repeat.value"
               :error="inputs.password_repeat.error"
               :label="$t('Повторіть пароль')"
               type="password"
               class="form__input"/>
    <AlertInline :value="overallErrorsMix" class="_warning"/>
    <div class="form__footer">
      <button type="submit"
              class="form__btn  base-btn">
        {{ $t('Зберегти') }}
      </button>
    </div>
  </form>
</template>

<script>
  import formMixins from '../mixins/formMixins'
  import AlertInline from './AlertInline'

  export default {
    mixins: [formMixins],
    components: {
      AlertInline
    },
    data() {
      return {
        inputs: {
          password: {
            value: null
          },
          password_repeat: {
            value: null
          }
        }
      }
    },
    computed: {
      pending() {
        return this.$store.state.user.pendingAuth
      },
      token() {
        return this.$route.query.token
      }
    },
    methods: {
      onSaveClick() {
        this.resetErrorsMix(this.inputs)

        const sendData = this.createSendDataMix(this.inputs)
        sendData.password_reset_token = this.token
        this.$store.dispatch('user/restorePassword', sendData).then((data) => {
          this.$router.push({name: 'home'})
          this.$nextTick(() => {
            this.$vModal.open('message', {
              message: data.message,
              btnText: this.$t('Вхід'),
              callBack: () => {
                this.$vModal.open('auth-login', {
                  redirect: {name: 'account'}
                })
              }
            })
          })
        }).catch(error => {
          this.handlerErrorMix(error, this.inputs)
        })
      }
    }
  }
</script>

<style lang="less">

</style>