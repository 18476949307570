<template>
  <ErrorView v-if="!$route.query.token" is-page/>
  <div v-else class="auth-change-password-page  container">
    <div class="auth-change-password-page__content  container-inner">
      <h1 class="auth-change-password-page__title">{{ $t('Новий пароль') }}</h1>
      <AuthRestorePassword class="auth-change-password-page__form"/>
    </div>
  </div>
</template>

<script>
  import AuthRestorePassword from '@/components/AuthRestorePassword'
  import ErrorView from '@/components/ErrorView'

  export default {
    components: {
      ErrorView,
      AuthRestorePassword
    }
  }
</script>

<style lang="less">

</style>